import React, { useContext } from 'react';
import { Link } from 'gatsby'
import "./postslist.scss";
import { isMobile } from 'react-device-detect';
import switchCategory from '../../utils/switchCategory.jsx';
import twitterbird from '../../../assets/images/twitterbird.svg'
import Tags from '../tag'
// import context
import  ThemeContext  from '../context/ThemeContext'; // 追加分
function IndexPage( five_ch,  twitter) {
    const allDataInDesc = [
      ...five_ch.map(e => {
        return(e)}),
      ...twitter.map(e => {
      return(e)}),
    ].sort((a, b) => { 
        return new Date(a.date) > new Date(b.date) ? -1 : 1; });
    return allDataInDesc
  }

const PostsList = (props) => {
    const contextValue = useContext(ThemeContext);
    if (isMobile) {
        if(props.category === '楽しむ'){
            return (
                <main>
                    <div>
                {
                    props.enjoyRanking
                    .map(
                        ({
                            node
                        }) => {
                            return (
                                <article className="postslist-mobile--onearticle">
                                    <Link to={node.fields.slug} className="postslist-mobile--onearticle__link" state={{ tabnumber: switchCategory(node.categories) }}>
                                    <div className="postslist-mobile--onearticle__image">
                                    <img
                                            src ={node.top_image}
                                            className="postslist-mobile--onearticle__image__img"
                                        />
                                    </div>
                                    <div className="postslist-mobile--onearticle__text">
                                                    <div className="postslist-mobile--onearticle__title">
                                                        {node.title}
                                                    </div>
                                                </div>
                                    </Link>
                                </article>
                            )
                            
                        })
                        
                }
                </div>
            </main>
            )
            }
        else if(props.category === '話題'){
            return (
                <main>
                    <div>
                {
                    props.twitterdata
                    .map(
                        ({
                            title,
                            categories,
                            top_image,
                            genre,
                            index,
                            fields: { slug },
                        }) => {
                            if(index >= props.maxNum)
                            return(<></>)
                            else
                            return (
                                <article className="postslist-mobile--onearticle">
                                        <Link to={slug} className="postslist-mobile--onearticle__link" state={{ tabnumber: switchCategory(categories) }}>
                                        <div className="postslist-mobile--onearticle__image">
                                            <img src ={top_image} className="postslist-mobile--onearticle__image__img"/>
                                        </div>
                                        <div className="postslist-mobile--onearticle__text">
                                            <div className="postslist-mobile--onearticle__title">
                                                {title}
                                            </div>
                                        </div>
                                    </Link>
                                </article>
                            )
                            
                        })
                }
                </div>
            </main>
            )
            }
        else if(contextValue.quoteSource === 'all'){
            return (
                <main className="postslist-mobile--main">
                    <div className="postslist-mobile--article__lists">
                        {
                            IndexPage(props.fivedata, props.twitterdata, props.maxNum)
                                .map(
                                    ({
                                        title,
                                        categories,
                                        top_image,
                                        genre,
                                        fields: { slug },
                                    },index) => {
                                        if(index >= props.maxNum)
                                            return(<></>)
                                        else{
                                        return (
                                                <article className="postslist-mobile--onearticle">
                                                    <Link to={slug} className="postslist-mobile--onearticle__link" state={{ tabnumber: switchCategory(categories) }}>
                                                    <div className="postslist-mobile--onearticle__image">
                                                        <img
                                                            src ={top_image}
                                                            className="postslist-mobile--onearticle__image__img"
                                                        />
                                                        {
                                                            (() =>{
                                                                if(categories == 'twitter'){
                                                                return <img src={twitterbird} width="26" height="26" style={{zIndex:10,position:'absolute',left:0}}/>
                                                            }})()
                                                        }
                                                    </div>
                                                    <div className="postslist-mobile--onearticle__text">
                                                        <div className="postslist-mobile--onearticle__title">
                                                            {title}
                                                        </div>
                                                    </div>
                                                    </Link>
                                                </article>
                                        )}
                                    })
                            }
                                </div>
                        </main>
                        );
            }
        else if (contextValue.quoteSource === '5ch'){
            return (
                <main>
                    <div>
                {
                    props.fivedata
                    .map(
                        ({
                            title,
                            categories,
                            top_image,
                            genre,
                            index,
                            fields: { slug },
                        }) => {
                            if(index >= props.maxNum)
                                        return(<></>)
                            else{
                            return (
                                <article className="postslist-mobile--onearticle">
                                    <Link to={slug} className="postslist-mobile--onearticle__link" state={{ tabnumber: switchCategory(categories) }}>
                                    <div className="postslist-mobile--onearticle__image">
                                    <img
                                            src ={top_image}
                                            className="postslist-mobile--onearticle__image__img"
                                        />
                                    </div>
                                    <div className="postslist-mobile--onearticle__text">
                                                    <div className="postslist-mobile--onearticle__title">
                                                        {title}
                                                    </div>
                                                </div>
                                    </Link>
                                </article>
                            )
                            }
                        })
                        
                }
                </div>
            </main>
            )
        }
        else if (contextValue.quoteSource === 'twitter'){
            return (
                <main>
                    <div>
                {
                    props.twitterdata
                    .map(
                        ({
                            title,
                            categories,
                            top_image,
                            genre,
                            index,
                            fields: { slug },
                        }) => {
                            if(index >= props.maxNum)
                                        return(<></>)
                            else{
                            return (
                                <article className="postslist-mobile--onearticle">
                                        <Link to={slug} className="postslist-mobile--onearticle__link" state={{ tabnumber: switchCategory(categories) }}>
                                        <div className="postslist-mobile--onearticle__image">
                                            <img src ={top_image} className="postslist-mobile--onearticle__image__img"/>
                                        </div>
                                        <div className="postslist-mobile--onearticle__text">
                                            <div className="postslist-mobile--onearticle__title">
                                                {title}
                                            </div>
                                        </div>
                                    </Link>
                                </article>
                            )
                            }
                        })
                }
                </div>
            </main>
            )
        }
    }
    else{
        if(props.category === '楽しむ'){
            return (
                <main className="postslist--main">
                <h2>
                {props.category !== 'TOP' ? props.category + "に関する記事" : "新着記事"}
            </h2>
            <div className="postslist--article__lists">
                {
                    props.enjoyRanking
                    .map(
                        ({
                            node
                        }) => {
                            return (
                                <article className="postslist--onearticle">
                                    <Link to={node.fields.slug} className="postslist--onearticle__link" state={{ tabnumber: switchCategory(node.categories) }}>
                                        <div className="postslist--onearticle__image">
                                        <img
                                        src ={node.top_image}
                                        className="postslist--onearticle__image__img"
                                        />
                                        </div>
                                        <div className="postslist--onearticle-info">
                                        <h2 className="postslist--onearticle__title">
                                                {node.title}
                                            </h2>
                                        </div>
                                    </Link>
                                </article>
                            )
                            
                        })
                }
            </div>
        </main>
            );
        }
        else if(props.category === '話題'){
            return (
                <main className="postslist--main">
            <h2>
            {props.category + "に関する記事"}
            </h2>
            <div className="postslist--article__lists">
                {
                    props.twitterdata
                    .map(
                        ({
                            title,
                            categories,
                            top_image,
                            genre,
                            index,
                            fields: { slug },
                        }) => {
                            if(index >= props.maxNum)
                            return(<></>)
                            else
                            return (
                                <article className="postslist--onearticle">
                                    <Link to={slug} className="postslist--onearticle__link" state={{ tabnumber: switchCategory(categories) }}>
                                        <div className="postslist--onearticle__image">
                                        <img
                                        src ={top_image}
                                        className="postslist--onearticle__image__img"
                                    />
                                        </div>
                                        <div className="postslist--onearticle-info">
                                        <h2 className="postslist--onearticle__title">
                                            {title}
                                            </h2>
                                        </div>
                                    </Link>
                                </article>
                            )
                            
                        })
                }
                </div>
        </main>
            );
        }
        else if(contextValue.quoteSource === 'all'){
        return (
        <main className="postslist--main">
            <h2>
                {props.category !== 'TOP' ? props.category + "に関する記事" : "新着記事"}
            </h2>
            <Tags taglist={props.taglist[props.category]} category={props.category}/>
            <div className="postslist--article__lists">
            {
                IndexPage(props.fivedata, props.twitterdata)
                .map(
                    ({
                        title,
                        categories,
                        top_image,
                        genre,
                        index,
                        fields: { slug },
                    }) => {
                        if(index >= props.maxNum)
                        return(<></>)
                        else
                        return (
                                <article className="postslist--onearticle">
                                    <Link to={slug} className="postslist--onearticle__link" state={{ tabnumber: switchCategory(categories) }}>
                                    <div className="postslist--onearticle__image">
                                        <img
                                            src ={top_image}
                                            className="postslist--onearticle__image__img"
                                        />
                                        {
                                                (() =>{
                                                    if(categories == 'twitter'){
                                                    return <img src={twitterbird} width="32" height="32" style={{zIndex:10,position:'absolute',left:0}}/>
                                                }})()
                                            }
                                    </div>
                                    <div className="postslist--onearticle-info">
                                        <h2 className="postslist--onearticle__title">
                                            {title}
                                        </h2>
                                    </div>
                                    </Link>
                                </article>
                        )
                                        
                    })
            }
                </div>
        </main>
        );
        }
        else if (contextValue.quoteSource === '5ch'){
            return (
                <main className="postslist--main">
            <h2>
            {props.category !== 'TOP' ? props.category + "に関する記事" : "新着記事"}
            </h2>
            <Tags taglist={props.taglist[props.category]} category={props.category}/>
            <div className="postslist--article__lists">
                {
                    props.fivedata
                    .map(
                        ({
                            title,
                            categories,
                            top_image,
                            genre,
                            index,
                            fields: { slug },
                        }) => {
                            if(index >= props.maxNum)
                            return(<></>)
                            else
                            return (
                                <article className="postslist--onearticle">
                                    <Link to={slug} className="postslist--onearticle__link" state={{ tabnumber: switchCategory(categories) }}>
                                        <div className="postslist--onearticle__image">
                                        <img
                                        src ={top_image}
                                        className="postslist--onearticle__image__img"
                                        />
                                        </div>
                                        <div className="postslist--onearticle-info">
                                        <h2 className="postslist--onearticle__title">
                                                {title}
                                            </h2>
                                        </div>
                                    </Link>
                                </article>
                            )
                            
                        })
                }
            </div>
        </main>
            );
        }
        else if (contextValue.quoteSource === 'twitter'){
            return (
                <main className="postslist--main">
            <h2>
            {props.category !== 'TOP' ? props.category + "に関する記事" : "新着記事"}
            </h2>
            <Tags taglist={props.taglist[props.category]} category={props.category}/>
            <div className="postslist--article__lists">
                {
                    props.twitterdata
                    .map(
                        ({
                            title,
                            categories,
                            top_image,
                            genre,
                            index,
                            fields: { slug },
                        }) => {
                            if(index >= props.maxNum)
                                    return(<></>)
                            else
                            return (
                                <article className="postslist--onearticle">
                                    <Link to={slug} className="postslist--onearticle__link" state={{ tabnumber: switchCategory(categories) }}>
                                        <div className="postslist--onearticle__image">
                                        <img
                                        src ={top_image}
                                        className="postslist--onearticle__image__img"
                                    />
                                        </div>
                                        <div className="postslist--onearticle-info">
                                        <h2 className="postslist--onearticle__title">
                                            {title}
                                            </h2>
                                        </div>
                                    </Link>
                                </article>
                            )
                            
                        })
                }
                </div>
        </main>
            );
        }
    }
}
    
export default PostsList