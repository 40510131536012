import React from 'react';
import { Link } from 'gatsby';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';

const Tags = (props) => {
if(props.taglist !== undefined){    
return(
  <ul >
    {(props.taglist || []).map(tag => (
      <Link to={`/${props.category}/${tag}/`} style={{ textDecoration: 'initial', color:'black' }}>
        <li style={{display:'inline',margin:'8px 8px 8px 8px'}} >
            <LocalOfferIcon style={{height:'18px'}}/>
            {tag}
        </li>
      </Link>
    ))}
  </ul>
)}
else
return(<></>)
}

export default Tags;