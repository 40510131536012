import React from "react"
import "./footer.css"
import Helmet from "react-helmet"
export default () => (
  <>
  <Helmet>
  <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
</Helmet>
    <footer className="footer">
    <a className="twitter-follow-button"
  href="https://twitter.com/wadainihannou"
  data-show-count='false'
data-show-screen-name='false'
  data-size="large">
フォローする</a>

    </footer>
  </>
)
