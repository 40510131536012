import React from "react"
import { Link } from 'gatsby'
import styled from 'styled-components';

const PaginateContainer = styled.div`
  padding: 1em;
  justify-content: space-evenly;
  width: 100%;
  display: flex;
  font-weight: bold;
  font-size: 1.2em;
`

export default ({ previousPagePath, nextPagePath }) => {
  return (
    <PaginateContainer>
      {previousPagePath && <Link to={previousPagePath}>≪Prev</Link>}
      {nextPagePath && <Link to={nextPagePath}>Next≫</Link>}
    </PaginateContainer>
  )
}
