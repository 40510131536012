import React from "react"
import Layout from "../components/layout"
import PostsList from "../components/postslist"
import Helmet from "react-helmet"
import '../styles/global.css'
import Paginate from '../components/pagenation'
import Footer from "../components/footer"
import  ThemeContext  from '../components/context/ThemeContext';

export default ({ location , pageContext, data}) => {
  if (typeof window !== `undefined`) {
   const { taglist, prevpage, nextpage } = pageContext
  var num;
  //document.body.style.backgroundColor = "#FF4E58";
  //console.log("pageContext",pageContext)
  //console.log("data.five_ch",data.five_ch.nodes)

  var fivetopArray = []
  var fiveentertainmentArray = []
  var fivedomesticArray = []
  var fiveoverseasArray = []
  var fivefunnyArray = []
  var fivesportsArray = []
  var fiveitArray = []
  var fivegourmetArray = []
  var fivehobbyArray = []

  var twittertopArray = []
  var twitterentertainmentArray = []
  var twitterdomesticArray = []
  var twitterfunnyArray = []
  var twitteroverseasArray = []
  var twittersportsArray = []
  var twitteritArray = []
  var twittergourmetArray = []
  var twitterhobbyArray = []
  var twittertopicArray = []

  var fivelengthArray =[]
  var twitterlengthArray =[]

  const { five_ch, twitter, movies } = data;

  five_ch.nodes.map((oneArticle) =>{
    fivetopArray.push(oneArticle)
    oneArticle.genre.forEach(function( value ) {
      switch(value.rough_genre){
        case "エンタメ":
          fiveentertainmentArray.push(oneArticle);
          break;
        case "国内":
          fivedomesticArray.push(oneArticle);
          break;
        case "海外":
          fiveoverseasArray.push(oneArticle);
          break;
        case "おもしろ":
          fivefunnyArray.push(oneArticle);
          break;
        case "スポーツ":
          fivesportsArray.push(oneArticle);
          break;
        case "IT":
          fiveitArray.push(oneArticle);
          break;
        case "グルメ":
          fivegourmetArray.push(oneArticle);
          break;
        case "趣味":
          fivehobbyArray.push(oneArticle);
          break;
      }
   });
  })

  fivelengthArray.push(fiveentertainmentArray.length)
  fivelengthArray.push(fivedomesticArray.length)
  fivelengthArray.push(fiveoverseasArray.length)
  fivelengthArray.push(fivefunnyArray.length)
  fivelengthArray.push(fivesportsArray.length)
  fivelengthArray.push(fiveitArray.length)
  fivelengthArray.push(fivegourmetArray.length)
  fivelengthArray.push(fivehobbyArray.length)

  twitter.nodes.map((oneArticle) =>{
    oneArticle.genre.forEach(function( value ) {
      switch(value.rough_genre){
        case "エンタメ":
          twitterentertainmentArray.push(oneArticle);
          twittertopArray.push(oneArticle)
          break;
        case "国内":
          twitterdomesticArray.push(oneArticle);
          twittertopArray.push(oneArticle)
          break;
        case "海外":
          twitteroverseasArray.push(oneArticle);
          twittertopArray.push(oneArticle)
          break;
        case "おもしろ":
          twitterfunnyArray.push(oneArticle);
          twittertopArray.push(oneArticle)
          break;
        case "スポーツ":
          twittersportsArray.push(oneArticle);
          twittertopArray.push(oneArticle)
          break;
        case "IT":
          twitteritArray.push(oneArticle);
          twittertopArray.push(oneArticle)
          break;
        case "グルメ":
          twittergourmetArray.push(oneArticle);
          twittertopArray.push(oneArticle)
          break;
        case "趣味":
          twitterhobbyArray.push(oneArticle);
          twittertopArray.push(oneArticle)
          break;
        case "話題":
          twittertopicArray.push(oneArticle)
      }
   });
  })

  twitterlengthArray.push(twitterentertainmentArray.length)
  twitterlengthArray.push(twitterdomesticArray.length)
  twitterlengthArray.push(twitteroverseasArray.length)
  twitterlengthArray.push(twitterfunnyArray.length)
  twitterlengthArray.push(twittersportsArray.length)
  twitterlengthArray.push(twitteritArray.length)
  twitterlengthArray.push(twittergourmetArray.length)
  twitterlengthArray.push(twitterhobbyArray.length)

  Math.max.apply({}, twitterlengthArray) 

  var maxnum = 0
  var tmp = 0
  for(var i=0;i<twitterlengthArray.length;i++){
      if((tmp = fivelengthArray[i] + twitterlengthArray[i]) > maxnum){
        maxnum =  tmp
      }
  }
  if (location.state === null || location.state === undefined) {
    num = 0
  }
  else {
    num = location.state.tabnumber
  }
  return (
    <>
    <Helmet
    bodyAttributes={{
        class: 'globalnody'
    }}
    />
    <ThemeContext.Consumer>
      {theme => (
      <Layout page="top" tabnumber={num} themeContext={theme}>
        <PostsList category="TOP" taglist={taglist} fivedata={fivetopArray} twitterdata={twittertopArray} fiveMax={Math.max.apply({}, fivelengthArray)} twitterMax={Math.max.apply({}, twitterlengthArray)} maxNum={maxnum} changeTab={theme.changeTab}/>
        <PostsList category="エンタメ" taglist={taglist} fivedata={fiveentertainmentArray} twitterdata={twitterentertainmentArray} fiveMax={Math.max.apply({}, fivelengthArray)} twitterMax={Math.max.apply({}, twitterlengthArray)} maxNum={maxnum} changeTab={theme.changeTab}/>
        <PostsList category="国内" taglist={taglist} fivedata={fivedomesticArray} twitterdata={twitterdomesticArray} fiveMax={Math.max.apply({}, fivelengthArray)} twitterMax={Math.max.apply({}, twitterlengthArray)} maxNum={maxnum} changeTab={theme.changeTab}/>
        <PostsList category="海外" taglist={taglist} fivedata={fiveoverseasArray} twitterdata={twitteroverseasArray} fiveMax={Math.max.apply({}, fivelengthArray)} twitterMax={Math.max.apply({}, twitterlengthArray)} maxNum={maxnum} changeTab={theme.changeTab}/>
        <PostsList category="おもしろ" taglist={taglist} fivedata={fivefunnyArray} twitterdata={twitterfunnyArray} fiveMax={Math.max.apply({}, fivelengthArray)} twitterMax={Math.max.apply({}, twitterlengthArray)} maxNum={maxnum} changeTab={theme.changeTab}/>
        <PostsList category="スポーツ" taglist={taglist} fivedata={fivesportsArray} twitterdata={twittersportsArray} fiveMax={Math.max.apply({}, fivelengthArray)} twitterMax={Math.max.apply({}, twitterlengthArray)} maxNum={maxnum} changeTab={theme.changeTab}/>
        <PostsList category="IT" taglist={taglist} fivedata={fiveitArray} twitterdata={twitteritArray} fiveMax={Math.max.apply({}, fivelengthArray)} twitterMax={Math.max.apply({}, twitterlengthArray)} maxNum={maxnum} changeTab={theme.changeTab}/>
        <PostsList category="グルメ" taglist={taglist} fivedata={fivegourmetArray} twitterdata={twittergourmetArray} fiveMax={Math.max.apply({}, fivelengthArray)} twitterMax={Math.max.apply({}, twitterlengthArray)} maxNum={maxnum} changeTab={theme.changeTab}/>
        <PostsList category="趣味" taglist={taglist} fivedata={fivehobbyArray} twitterdata={twitterhobbyArray} fiveMax={Math.max.apply({}, fivelengthArray)} twitterMax={Math.max.apply({}, twitterlengthArray)} maxNum={maxnum} changeTab={theme.changeTab}/>
        <PostsList category="楽しむ" taglist={taglist} fivedata={fivehobbyArray} twitterdata={twitterhobbyArray} fiveMax={Math.max.apply({}, fivelengthArray)} twitterMax={Math.max.apply({}, twitterlengthArray)} maxNum={maxnum} enjoyRanking={movies.edges} changeTab={theme.changeTab}/>
        <PostsList category="話題" taglist={taglist} fivedata={[]} twitterdata={twittertopicArray} fiveMax={Math.max.apply({}, fivelengthArray)} twitterMax={Math.max.apply({}, twitterlengthArray)} maxNum={maxnum} changeTab={theme.changeTab}/>
      </Layout >
      )}
    </ThemeContext.Consumer>
    <Paginate previousPagePath={prevpage} nextPagePath={nextpage} />
    <Footer />
    </>
  )
}
else return(<></>)
}

export const query = graphql`
query ($periodStartDate: Date, $periodEndDate: Date) {
  five_ch : allFiveChJson(filter: {date: {gte: $periodStartDate, lt: $periodEndDate}},sort: {fields: date, order: DESC}){
    nodes {
      title
      date(formatString: "YYYY.MM.DD HH:mm")
      categories
      top_image
      genre{
        rough_genre
        strict_genre
      }
      fields {
          slug
          }
    }
  }
  twitter : allTwitterJson(filter: {date: {gte: $periodStartDate, lt: $periodEndDate}},sort: {fields: date, order: DESC}){
      nodes {
        title
        date(formatString: "YYYY.MM.DD HH:mm")
        categories
        top_image
        genre{
          rough_genre
          strict_genre
        }
        fields {
            slug
            }
      }
    }
    movies : allMovieJson (
      sort: { fields: date ,order: DESC}
      ){
        edges{
          node{
            fields {
              slug
               }
            date
            title
            top_image
            }
         }
      }
}
`;